// src/components/Products/Product.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Product.css";
import ProductData from "../db/ProductsDB/ProductsData";

const Product = ({ searchTerm, selectedTag }) => {
  const navigate = useNavigate();

  const filteredProducts = ProductData.filter((product) => {
    if (selectedTag) {
      return product.tags.includes(selectedTag);
    }
    if (searchTerm) {
      return product.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return true;
  });

  const handleCardClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  return (
    <div className="Product">
      <div className="Recommended-Cards">
        <div className="Recommended grid grid-cols-2 lg:grid-cols-4">
          {filteredProducts.map((product) => (
            <div
              className="Rec-Card cursor-pointer"
              key={product.id}
              onClick={() => handleCardClick(product.id)}
            >
              <div className="ProdI ">
                <img className="Prod-Img rounded-t-md" src={product.img} alt={product.name} />
              </div>
              {/* <hr /> */}
              <div className="Prod-Heading">
                <div className="Prod-Name">
                  <h3>{product.name}</h3>
                </div>
                <div className="Prod-Price">
                  <h3>{product.price}</h3>
                </div>
              </div>
              <div className="Prod-Description">
                <p className="text-sm">{product.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
