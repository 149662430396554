import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IoIosCloseCircleOutline } from "react-icons/io";
import ContactForm from './ContactForm';
import Landing from '../images/Landing.jpg';
import Acidity from '../images/Acidity.png';
import Kidney from '../images/Kidney.png';
import food from '../images/food.png';
import { Link, useNavigate } from 'react-router-dom';
import Bot from '../images/bot.png';

const LandingPage = () => {
  const [launchShowPopup, setLaunchShowPopup] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);

  

  const LaunchingPopup = () => {
    setLaunchShowPopup(!launchShowPopup);
  }; 

  const showWhatsappPopup = () =>{
    setWhatsappPopup(!whatsappPopup);
  };

  const handleOrderButtonClick = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth > 768) {
      LaunchingPopup();
    } else {
      showWhatsappPopup();
    }
  };


const navigate = useNavigate();
  const handleAcidityClick = () =>{
    window.scrollTo(0, 0);

    navigate('/timeline');

    }

    const handleSearchClick = ()=>{
      navigate('/products')
    }

  return (
    <div>
      <div className="bg-cover bg-center h-500px lg:w-full lg:h-750px lg:py-36" style={{ backgroundImage: `url(${Landing})` }}>
        <h1 className='text-6xl lg:text-8xl font-bold text-white pt-32 p-4 pb-0 stroke-1 text-transparent text-center stroke-cyan-500'>Think Fruits</h1>
        <h1 className='text-3xl lg:text-6xl font-bold text-white stroke-1 text-transparent text-center stroke-cyan-500'>#GoFalhari</h1>
        {/* <div className='w-fit m-auto'>
          <button onClick={handleOrderButtonClick} className='py-2 px-4 lg:py-3 lg:px-8 md:px-10 border-2 text-white font-bold text-xl hover:bg-red-600'>Order Now</button>
        </div> */}
        <div onClick={handleSearchClick} className="search-bar m-auto">
          <input
            type="text"
            placeholder="Search for fruit products..."
            className="border-2 border-gray-300 rounded-lg p-2 w-2/3 focus:outline-none transition duration-300"
            // onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="ml-3 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-300">
            Search
          </button>
        </div>
      </div>

      <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators={false} showStatus={false} showArrows={false} dynamicHeight className='mx-4 lg:mt-12 lg:pl-12 mt-4 lg:w-2/4 lg:m-auto rounded-md border relative'>
        <div className="bg-cover bg-center rounded-md">
          <div className='flex '>
            <div className='w-2/5 lg:w-48 h-20 lg:h-44'>
              <img className='w-2/5 lg:w-48 lg:h-44 h-20 object-cover' src={Acidity} alt="Acidity" />
            </div>
            <div className='text-black text-left pt-4 lg:pt-12'>
              <p className='font-bold text-xl lg:text-3xl color leading-none'>80%</p>
              <p className='text-sm lg:text-xl font-semibold leading-none'>Indians are Suffering from Acidity</p>
            </div>
          </div>
          <div className='w-fit m-auto'>
            <button onClick={handleOrderButtonClick} className='py-2 px-4 lg:py-3 lg:px-8 md:px-10 border-2 text-white font-bold text-xl hover:bg-red-600'>Order Now</button>
          </div>
        </div>
        <div className="bg-cover bg-center rounded-md">
          <div className='flex '>
            <div className='w-32 h-20 lg:w-48 lg:h-44'>
              <img className='w-32 h-20 lg:w-48 lg:h-44 object-cover' src={Kidney} alt="Kidney" />
            </div>
            <div className='text-black text-left pt-4 lg:pt-12 pl-2'>
              <p className='font-bold lg:text-3xl text-lg color leading-none'>Falhari's Acidity Checker</p>
              <p className='text-sm lg:text-xl font-semibold leading-none'>Check your acidity</p>
            </div>
          </div>
          <div className='w-fit m-auto'>
            <button onClick={handleOrderButtonClick} className='py-2 px-4 lg:py-3 lg:px-8 md:px-10 border-2 text-white font-bold text-xl hover:bg-red-600'>Order Now</button>
          </div>
        </div>
        <div className="bg-cover bg-center rounded-md">
          <div className='flex '>
            <div className='w-20 h-20 lg:w-48 lg:h-44 '>
              <img className='w-2/5 lg:w-48 lg:h-44 h-20 object-cover' src={food} alt="Food" />
            </div>
            <div className='text-black text-left pt-4 lg:pt-12'>
              <p className='font-bold lg:text-3xl text-xl color leading-none'>Know Your Food</p>
              <p className='text-sm lg:text-xl font-semibold leading-none'>Understand your food habits</p>
            </div>
          </div>
          <div className='w-fit m-auto'>
            <button onClick={handleOrderButtonClick} className='py-2 px-4 lg:py-3 lg:px-8 md:px-10 border-2 text-white font-bold text-xl hover:bg-red-600'>Order Now</button>
          </div>
        </div>
        
      </Carousel>
      <div className="absolute right-4 lg:right-96">
      {/* <a href="https://app.falhari.com/store/falhari-falhari-sg-palya-takeaway/acidity-meter"  > */}
          <button onClick={handleAcidityClick} className="lg:text-lg bg-gradient-to-r from-orange-500 to-red-600 text-white text-sm px-6 py-2 shadow-lg hover:from-red-600 hover:to-yellow-500 transition duration-300 rounded-b-lg">
            Check Acidity
          </button>
        
        </div>

        {launchShowPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded w-2/5">
            <IoIosCloseCircleOutline className="close-icon absolute top-0 right-0 mt-2 mr-2 cursor-pointer text-black font-bold text-2xl" onClick={LaunchingPopup} />
            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-bold'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>

            <p className='text-xl font-medium text-red-500'>*Note:Please Use Mobile for Better experience</p>
            <button className='bg-theme px-4 py-2 text-white text-semibold rounded' onClick={LaunchingPopup}>OK</button>
          </div>
        </div>
      )}

      {whatsappPopup && (
        <div className="popup-wrapper ">
          <div className="popup-content">
            <IoIosCloseCircleOutline className="close-icon" onClick={showWhatsappPopup} />

            <img className='w-4/6  m-auto object-cover' src={Bot} alt="Launching soon bot" />
            <p className='text-xl font-medium leading-none'>Ordering Made easy</p>
            <p className='text-3xl font-bold'>Order Directly Via <span className='text-green-500'>Whatsapp</span></p>
            <Link to='https://wa.me/message/5TJSEZZBZM5LM1'>
            <button  className='bg-theme px-4 py-2 text-white text-semibold rounded w-full'>Order Now</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPage;
