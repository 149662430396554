import React, { useEffect, useState } from "react";
import "./Search.css";
import ProductData from "./db/ProductsDB/ProductsData";
import { IoReloadCircleOutline } from "react-icons/io5";

const SearchContainer = ({ setSearchTerm, onTagClick }) => {
  const [randomTags, setRandomTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getRandomTags = () => {
    const allTags = ProductData.flatMap((product) => product.tags);
    const uniqueTags = [...new Set(allTags)];
    const shuffledTags = uniqueTags.sort(() => 0.5 - Math.random());
    return shuffledTags.slice(0, 10);
  };

  const loadMoreTags = () => {
    setIsLoading(true);
    setTimeout(() => {
      setRandomTags(getRandomTags());
      setIsLoading(false);
    }, 300); // Simulates a delay for smooth transition
  };

  useEffect(() => {
    setRandomTags(getRandomTags());
  }, []);

  return (
    <div className="Search">
      <div className="search-container">
        <div>
          <p className="text-3xl font-bold text-center">
            Innovative fruit products by <br />
            <span className="text-orange-500">#Falhari</span>
          </p>
        </div>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search for fruit products..."
            className="border-2 border-gray-300 rounded-lg p-2 w-2/3 focus:outline-none transition duration-300"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button className="ml-3 bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600 transition duration-300">
            Search
          </button>
        </div>

        {/* Display the random tags */}
        <div
          className={`tag-container mt-5 flex flex-wrap justify-center gap-2 md:gap-4 w-full md:w-4/5 transition-all duration-500 ${
            isLoading ? "opacity-0" : "opacity-100"
          }`}
        >
          {randomTags.map((tag, index) => (
            <span className="bg-white px-2 py-1 rounded-full shadow" key={index}>
              <span
                className="bg-gradient-to-r from-blue-500 to-purple-500 text-black font-semibold py-2 px-4 rounded-full border-1 bg-clip-text text-transparent animate-shimmer cursor-pointer"
                onClick={() => onTagClick(tag)}
              >
                {tag}
              </span>
            </span>
          ))}
        </div>

        {/* Load more tags button */}
        <div className="flex justify-center mt-4 shadow bg-white rounded-full mb-18 cursor-pointer transition duration-300 transform active:scale-90 ">
          <div className="pt-2 pl-2 text-orange-500 transition duration-300 transform active:scale-90  active:bg-yellow-600">
            <IoReloadCircleOutline size={24} />
          </div>
          <div
            className="bg-gradient-to-r from-orange-500 to-yellow-500 text-black font-semibold py-2 pr-4 rounded-full border-1 bg-clip-text text-transparent "
            onClick={loadMoreTags}
          >
            Load More Tags
          </div> 
        </div>
      </div>
    </div>
  );
};

export default SearchContainer;
