// src/pages/ProductCatalogue.js
import React, { useState } from "react";
import SearchContainer from "../components/Search";
import Product from "../components/Products/Product";

const ProductCatalogue = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagClick = (tag) => {
    setSelectedTag(tag);
    setSearchTerm(""); // Reset the search term when a tag is selected
  };

  return (
    <div>
      <SearchContainer setSearchTerm={setSearchTerm} onTagClick={handleTagClick} />
      <Product searchTerm={searchTerm} selectedTag={selectedTag} />
    </div>
  );
};

export default ProductCatalogue;
