import Product1 from "../../../assets/productCategory/VratAmrit Fruit Salad.jpg";
import Product2 from "../../../assets/productCategory/Trail Mix.jpg";
import Product3 from "../../../assets/productCategory/Exotico Salad.jpg";
import Product4 from "../../../assets/productCategory/Hangover Bliss Salad.jpg";
import Product5 from "../../../assets/productCategory/Product1.png";
import Product6 from "../../../assets/productCategory/Product2.png";
import Product7 from "../../../assets/productCategory/Product3.png";
import Product8 from "../../../assets/productCategory/Product4.png";

const ProductData = [
  {
    id: 1,
    img: Product1,
    name: "VratAmrit Fruit Salad",
    price: "Rs 200",
    tags: ["Immunity", "Heart health"],
    description:"If you are on a fast, this salad is just the force that the Universe wants to be with you. Pomegranate, Kiwi, Banana, Pineapple, Watermelon || Makhana, Walnuts, Almonds and Brown Raisins.",
    recipe: [
      { ingredient: "Pomegranate", quantity: "80g" },
      { ingredient: "Banana", quantity: "80g" },
      { ingredient: "Kiwi", quantity: "80g" },
      { ingredient: "Watermelon", quantity: "80g" },
      { ingredient: "Pineapple", quantity: "80g" },
      { ingredient: "Almond", quantity: "5g" },
      { ingredient: "Walnut", quantity: "5g" },
      { ingredient: "Brown Kishmish", quantity: "5g" },
      { ingredient: "Makhana", quantity: "10g" }
    ]
  },

  {
    id: 2,
    img: Product2,
    name: "Dry Fruit Mix",
    price: "Rs 80",
    tags: ["High cholesterol", "Heart health", "Memory"],
    description:"A combination of almonds, walnuts, raisins, pumpkin seeds, flax seeds, and dried cranberries to fill your snacktime with taste and health.",
    recipe: [
      { ingredient: "Almond", quantity: "5g" },
      { ingredient: "Walnut", quantity: "5g" },
      { ingredient: "Brown Raisin", quantity: "5g" },
      { ingredient: "Flax Seeds", quantity: "5g" },
      { ingredient: "Pumpkin Seed", quantity: "5g" },
      { ingredient: "Cranberry", quantity: "5g" },      
    ]
  },
  {
    id: 3,
    img: Product3,
    name: "Exotico Salad",
    tags: ["Cold & flu", "Immunity", "Vision", "Fatty Liver", 'IBS'],
    price: "Rs 300",
    description:"This is the real exotic thing to start your rewarding journey with fruits. Pineapple, Honeymelon, Apple, Kiwi, Malta, Red Grapes, Pomegranate, Strawberries || Blueberries, Cranberries, Mint Leaves",
    recipe: [
      { ingredient: "Pineapple", quantity: "60g" },
      { ingredient: "Honeymelon", quantity: "60g" },
      { ingredient: "Apple", quantity: "60g" },
      { ingredient: "Pomegranate", quantity: "50" },
      { ingredient: "Egyptian Orange", quantity: "50g" },
      { ingredient: "Kiwi", quantity: "50g" },
      { ingredient: "Cranberry", quantity: "5g" },
      { ingredient: "Blueberry", quantity: "5g" },
      { ingredient: "Mint", quantity: "5g" },
      { ingredient: "Strawberry", quantity: "5g" },


    ]
  },
  {
    id: 4,
    img: Product4,
    name: "Hangover Bliss",
    tags: ["Constipation", "Hydration", "Digestion", "acidity"],
    price: "Rs 300",
    description:"Say goodbye to hungover mornings with this refreshing combination of fruits, dry fruits and coconut water. Salad (Banana, Kiwi, Dates, Watermelon, Pear || Almonds, Walnut, Blueberry, Mint Leaves, Lemon) + Coconut Water",
    recipe: [
      { ingredient: "Frozen Banana", quantity: "1 whole" },
      { ingredient: "Mixed Berries", quantity: "100g" },
      { ingredient: "Almond Milk", quantity: "50ml" },
      { ingredient: "Granola", quantity: "30g" },
      { ingredient: "Chia Seeds", quantity: "1 tsp" },
      { ingredient: "Sliced Fruits (kiwi, banana)", quantity: "50g" }
    ]
  },
  // {
  //   id: 5,
  //   img: Product5,
  //   name: "Protein Booster",
  //   tags: ["Muscle health", "Blood pressure", "Heart Health"],
  //   price: "Rs 150",
  //   description:
  //     "A crunchy mix of dried fruits and nuts, perfect for a healthy snack on the go.",
  //   recipe: [
  //     { ingredient: "Raisins", quantity: "30g" },
  //     { ingredient: "Cranberries", quantity: "30g" },
  //     { ingredient: "Dried Apricots", quantity: "30g" },
  //     { ingredient: "Almonds", quantity: "40g" },
  //     { ingredient: "Cashews", quantity: "40g" },
  //     { ingredient: "Walnuts", quantity: "40g" },
  //     { ingredient: "Sea Salt", quantity: "1 pinch (optional)" }
  //   ]
  // },
  // {
  //   id: 6,
  //   img: Product6,
  //   name: "Tropical Fruit Platter",
  //   tags: ["Fruit Platter", "Tropical", "Health"],
  //   price: "Rs 199",
  //   description:
  //     "An exotic mix of fresh tropical fruits, ideal for a refreshing treat.",
  //   recipe: [
  //     { ingredient: "Mango", quantity: "50g" },
  //     { ingredient: "Pineapple", quantity: "50g" },
  //     { ingredient: "Papaya", quantity: "50g" },
  //     { ingredient: "Kiwi", quantity: "50g" },
  //     { ingredient: "Mint Leaves", quantity: "a few" },
  //     { ingredient: "Honey", quantity: "1 tsp (optional)" }
  //   ]
  // },
  // {
  //   id: 7,
  //   img: Product7,
  //   name: "Fruit Infused Water",
  //   tags: ["Infused Water", "Detox", "Health"],
  //   price: "Rs 79",
  //   description:
  //     "A hydrating drink infused with fresh fruits for a detoxifying boost.",
  //   recipe: [
  //     { ingredient: "Water", quantity: "1 liter" },
  //     { ingredient: "Lemons", quantity: "50g (sliced)" },
  //     { ingredient: "Oranges", quantity: "50g (sliced)" },
  //     { ingredient: "Berries", quantity: "30g" },
  //     { ingredient: "Mint Leaves", quantity: "a few" },
  //     { ingredient: "Cucumber Slices", quantity: "50g" }
  //   ]
  // },
  // {
  //   id: 8,
  //   img: Product8,
  //   name: "Fruit Yogurt Parfait",
  //   tags: ["Parfait", "Yogurt", "Health"],
  //   price: "Rs 159",
  //   description:
  //     "Layers of creamy yogurt, fresh fruits, and granola for a healthy breakfast or snack.",
  //   recipe: [
  //     { ingredient: "Yogurt", quantity: "200ml" },
  //     { ingredient: "Granola", quantity: "50g" },
  //     { ingredient: "Mixed Berries", quantity: "50g" },
  //     { ingredient: "Honey", quantity: "1 tsp" },
  //     { ingredient: "Nuts", quantity: "10g (optional)" }
  //   ]
  // }
];

export default ProductData;
